/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Gallery Sliders
        var swiper = new Swiper('.slider-news', {
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          pagination: {
            el: ".swiper-pagination",
            dynamicBullets: true,
            clickable: true,
          },
        });

        // Logos Slider
        var swiper2 = new Swiper('.slider-logos', {
          slidesPerView: "auto",
          slidesPerGroupAuto: true,
          spaceBetween: 30,
          grabCursor: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });

        // Gallery Sliders
        var swiper3 = new Swiper('.slider-gallery', {
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          pagination: {
            el: ".swiper-pagination",
            dynamicBullets: true,
            clickable: true,
          },
        });

        // Lightbox options
        lightbox.option({
          'resizeDuration': 200,
          'wrapAround': true,
          'showImageNumberLabel': false,
          'disableScrolling': true
        });

        // Scrollbar
        $('#blocs').perfectScrollbar();

        // Services bloc
        var $gallery = $('.bloc-link > .slider-services');
        $gallery.hide();
        $('.bloc-link:eq(0)').addClass('active');
        $('.bloc-link.active').find($gallery).clone().appendTo('.services');

        var swiper4 = new Swiper('.services > .slider-services', {
          autoHeight: true,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          pagination: {
            el: ".swiper-pagination",
            dynamicBullets: true,
            clickable: true,
          },
        });

        $('#blocs > .bloc-link').click(function(e) {
          e.preventDefault(); //prevent the link from being followed
          $('#blocs > .bloc-link').removeClass('active');
          $('.services > .swiper').remove();
          $(this).addClass('active');
          $(this).find($gallery).clone().appendTo('.services');

          var swiper5 = new Swiper('.services > .slider-services', {
            autoHeight: true,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            },
            pagination: {
              el: ".swiper-pagination",
              dynamicBullets: true,
              clickable: true,
            },
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
